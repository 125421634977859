import { Box, IconButton, Stack, TextareaAutosize, Tooltip, Typography, styled } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { InspectPageComponentRow } from "./inspectPageComponentRow";
import { InspectPageCustomSpecText } from "./inspectPageCustomSpecText";
import { useDatabase, useUser } from "reactfire";
import { removeConnRows, removeSpecRows, saveConnRows, saveSpecRows } from "../../../../firebase/functions/saveTechRows";

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { getComponentText } from "../../../../functions/DisplayFunctions/getComponentText";
import { useContextAuth } from "../../../../authContext/AuthHook";
import { getConnectedComponentText } from "../../../../functions/DisplayFunctions/getConnectedText";
import { SessionParamsEnum } from "../../../../enums";
import { useSessionParams } from "../../../../contexts";
import { PRODUCTLINE_GREEN } from "../../../../constants/productlineConstants";
import { useGetSystemFluidOptions } from "../../../../hooks/useGetSystemFluidOptions";

const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: '98%',
    minHeight: 100,
    maxHeight: 630,
    resize: 'vertical',
    fontSize: "12px",
    fontFamily: theme.typography.fontFamily,
    '&:focus': {
        outline: 'none',
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
    },
}));


export const InspectPageCustomShuntData = ({ useCurrent, shuntData }) => {
    const systemFluidOptions = useGetSystemFluidOptions();
    const { user } = useContextAuth();
    const database = useDatabase();
    const sessionParams = useSessionParams();
    //console.log("Data: ", shuntData.SpecData)
    const [openEdit, setOpenEdit] = useState(false);
    const [specValue, setSpecValue] = useState('')
    const [specRows, setSpecRows] = useState([]);

    const [connValue, setConnValue] = useState('')
    const [connRows, setConnRows] = useState([]);

    const [reset, setReset] = useState(false)
    const [saveTrigger, setSaveTrigger] = useState(false);

    useEffect(() => {
        if (shuntData != null && Object.keys(shuntData).length > 0) {
            let triggerResaveTrigger = false;
            //console.log("Start: ", reset, )
            if (reset === true && shuntData?.Baseinfo?.isCustom !== true) {
                if (shuntData?.SpecData?.specList != null || shuntData?.SpecData?.connList != null) {
                    removeAllSpecData();
                }
            }

            if (shuntData?.SpecData?.specList != null && reset === false) {
                //console.log("Rows: ", shuntData?.SpecData?.specList)
                let specArray = []
                let specArrayObj = []
                for (let index = 0; index < shuntData.SpecData.specList.length; index++) {
                    const element = shuntData.SpecData.specList[index];
                    let textString = "";
                    if (element.num != null && element.num !== '') {
                        if (element.num < 10) {
                            textString += element.num + " st. ";
                        }
                        else {
                            textString += element.num + " st. ";
                        }
                    }
                    else if (element.indent === true && element.text.startsWith('\t') === false) {
                        textString += '\t';
                    }
                    textString += element.text;
                    //console.log("Textstring: ", textString);
                    //console.log("Row: ", textString)
                    specArray.push(textString);
                    specArrayObj.push({ text: textString })
                }
                setSpecValue(specArray.join('\n'));
                setSpecRows(specArrayObj)
            }
            else {
                let specArray = []
                let specArrayObj = []

                const localCompList = [];

                localCompList.push({ rows: getComponentText('blockInfo', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('greenException', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('returnVentsSabo', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('material', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('cabinet', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('insulation', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('controlValve', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('exchanger', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('actuators', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('adjustValve', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('cutOff', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('returnVents', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('thermo', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('safetyValve', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('extraBV', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('drainValve', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('pump', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('dripPlate', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('muff', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('stand', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('manometer', false, shuntData, systemFluidOptions), indent: true });
                localCompList.push({ rows: getComponentText('systemFluid', false, shuntData, systemFluidOptions), indent: false });
                localCompList.push({ rows: getComponentText('infoSign', false, shuntData,systemFluidOptions), indent: false });

                localCompList.forEach((specRow) => {
                    //console.log("Row: ", specRow);

                    specRow.rows.forEach((row) => {
                        let textString = "";

                        // @ts-ignore
                        if (row.num != null && row.num !== '') {
                            if (row.num < 10) {
                                textString += row.num + " st. ";
                            }
                            else {
                                textString += row.num + " st. ";
                            }
                        }
                        else if (specRow.indent === true && row.text != null && row.text.startsWith('\t') === false) {
                            textString += '\t';
                        }

                        textString += row.text;

                        //console.log("Textstring: ", textString);
                        //console.log("Row: ", textString)
                        specArray.push(textString);
                        specArrayObj.push({ text: textString })
                    });
                })

                setSpecValue(specArray.join('\n'));
                setSpecRows(specArrayObj);

                if (shuntData?.Baseinfo?.isCustom === true && reset === true) {
                    triggerResaveTrigger = true;
                }
            }

            if (shuntData?.SpecData?.connList != null && reset === false) {
                let specConnArray = [];
                let specConnArrayObj = [];

                for (let index = 0; index < shuntData.SpecData.connList.length; index++) {
                    const element = shuntData.SpecData.connList[index];
                    let textString = "";
                    if (element.num != null && element.num !== '') {
                        if (element.num < 10) {
                            textString += element.num + " st. ";
                        }
                        else {
                            textString += element.num + " st. ";
                        }
                    }
                    else if (element.indent === true && element.text.startsWith('\t') === false) {
                        textString += '\t';
                    }
                    textString += element.text;
                    //console.log("Textstring: ", textString);
                    //console.log("Row: ", textString)
                    specConnArray.push(textString);
                    specConnArrayObj.push({ text: textString })
                }
                setConnValue(specConnArray.join('\n'));
                setConnRows(specConnArrayObj)
            }
            else {
                let specConnArray = []
                let specConnArrayObj = []

                let localConnRow = [];

                localConnRow.push({ rows: getConnectedComponentText('connectedTemp', false, shuntData), indent: false });
                localConnRow.push({ rows: getConnectedComponentText('connectedDiff', false, shuntData), indent: false });
                localConnRow.push({ rows: getConnectedComponentText('connectedEnergy', false, shuntData), indent: false });

                localConnRow.forEach((connRow) => {
                    connRow.rows.forEach((row) => {
                        let textString = "";

                        // @ts-ignore
                        if (row.num != null && row.num !== '') {
                            if (row.num < 10) {
                                textString += row.num + " st. ";
                            }
                            else {
                                textString += row.num + " st. ";
                            }
                        }
                        else if (connRow.indent === true && row.text != null && row.text.startsWith('\t') === false) {
                            textString += '\t';
                        }

                        textString += row.text;

                        //console.log("Textstring: ", textString);
                        //console.log("Row: ", textString)
                        specConnArray.push(textString);
                        specConnArrayObj.push({ text: textString })
                    });
                });

                setConnValue(specConnArray.join('\n'))
                setConnRows(specConnArrayObj);

                if (shuntData?.Baseinfo?.isCustom === true && reset === true) {
                    triggerResaveTrigger = true;
                }
            }

            if (triggerResaveTrigger === true) {
                setSaveTrigger(true);
            }
        }

    }, [shuntData, systemFluidOptions, reset]);

    useEffect(() => {
        if (saveTrigger === true) {
            setSaveTrigger(false);
            saveSpecData();
            if (connRows?.length > 0) {
                saveConnSpecData();
            }

            if (reset === true) {
                setReset(false)
            }
        }
    }, [saveTrigger])

    /*
    useEffect(() => {
        //console.log("ShuntData:", shuntData, techRows)
        if (shuntData?.SpecData?.specList != null) {
            console.log("Rows: ", shuntData?.SpecData?.specList)
            let specArray = []
            let specArrayObj = []
            for (let index = 0; index < shuntData.SpecData.specList.length; index++) {
                const element = shuntData.SpecData.specList[index];
                let textString = "";
                if (element.num != null && element.num !== '') {
                    if (element.num < 10) {
                        textString += element.num + " st. ";
                    }
                    else {
                        textString += element.num + " st. ";
                    }
                }
                else if (element.indent === true && element.text.startsWith('\t') === false) {
                    console.log("Textstring (tab1): ", textString);
                    textString += '\t';
                    console.log("Textstring (tab2): ", textString);
                }
                textString += element.text;
                console.log("Textstring: ", textString);
                console.log("Row: ", textString)
                specArray.push(textString);
                specArrayObj.push({ text: textString })
            }
            setCurrentShunt(shuntData?.id);
            setSpecValue(specArray.join('\n'));
            setSpecRows(specArrayObj)
        }
        else if (currentShunt !== shuntData?.id && shuntData?.id != null) {
            setCurrentShunt(shuntData?.id);
            setSpecValue('');
            setSpecRows([]);
        }
    }, [shuntData]);
    */

    function handleChangeSpec(e) {
        setSpecValue(e.target.value);
    }

    function handleChangeConnSpec(e) {
        setConnValue(e.target.value);
    }

    function handleKeyDown(e) {
        if (e.key === 'Tab') {
            e.preventDefault();

            const cursorPosition = e.target.selectionStart;
            const cursorEndPosition = e.target.selectionEnd;
            const tab = '\t';

            e.target.value = specValue.substring(0, cursorPosition) + tab + specValue.substring(cursorEndPosition);

            e.target.selectionStart = cursorPosition + tab.length;
            e.target.selectionEnd = cursorPosition + tab.length;
            setSpecValue(e.target.value);
        }
    }

    function handleKeyDownSpec(e) {
        if (e.key === 'Tab') {
            e.preventDefault();

            const cursorPosition = e.target.selectionStart;
            const cursorEndPosition = e.target.selectionEnd;
            const tab = '\t';

            e.target.value = connValue.substring(0, cursorPosition) + tab + connValue.substring(cursorEndPosition);

            e.target.selectionStart = cursorPosition + tab.length;
            e.target.selectionEnd = cursorPosition + tab.length;
            setConnValue(e.target.value);
        }
    }

    function saveAllData() {
        saveSpecData();
        if (connRows?.length > 0) {
            saveConnSpecData();
        }
        SessionParamsEnum.UpdateValues.setContextValue(sessionParams, !SessionParamsEnum.UpdateValues.getContextValue(sessionParams));
        setOpenEdit(false);
    }

    function removeAllSpecData() {
        console.log("Remove!")
        removeSpecRows(database, shuntData.NO_ID_FIELD, SessionParamsEnum.SessionID.getContextValue(sessionParams));
        removeConnRows(database, shuntData.NO_ID_FIELD, SessionParamsEnum.SessionID.getContextValue(sessionParams));
        setReset(false);
    }

    function saveSpecData() {
        //console.log("Data", techValue);
        const rows = specValue.split('\n');
        let textRows = [];
        let textRowsLocal = [];
        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            let dataObj = {}
            let amountSplit = element.split('st.')
            //console.debug('Split: ', amountSplit)
            if (amountSplit.length > 1 && isNaN(Number(amountSplit[0].trim())) === false) {
                dataObj['num'] = amountSplit[0].trim();
                dataObj['text'] = amountSplit[1].trim();
                dataObj['showAmount'] = true;
                dataObj['indent'] = true;
                //console.debug('Split: ', Number(amountSplit[0].trim()))
            }
            else if (element.startsWith('\t') === true) {
                //console.log("Tab", element);
                dataObj['text'] = element;
                dataObj['showAmount'] = false;
                dataObj['indent'] = true;
            }
            else {
                dataObj['text'] = element;
                dataObj['showAmount'] = false;
                dataObj['indent'] = false;
            }

            textRows.push(dataObj);
            textRowsLocal.push({ text: element })
            //console.log("Index: ", index, 'Row: ', element)
        }

        //console.log("Rows: ", textRows)
        setSpecRows(textRowsLocal);
        saveSpecRows(database, shuntData.NO_ID_FIELD, SessionParamsEnum.SessionID.getContextValue(sessionParams), textRows);
    }

    function saveConnSpecData() {
        //console.log("Data", techValue);
        const rows = connValue.split('\n');
        let textRows = [];
        let textRowsLocal = [];
        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            let dataObj = {}
            let amountSplit = element.split('st.')
            //console.debug('Split: ', amountSplit)
            if (amountSplit.length > 1 && isNaN(Number(amountSplit[0].trim())) === false) {
                dataObj['num'] = amountSplit[0].trim();
                dataObj['text'] = amountSplit[1].trim();
                dataObj['showAmount'] = true;
                dataObj['indent'] = true;
                //console.debug('Split: ', Number(amountSplit[0].trim()))
            }
            else if (element.startsWith('\t') === true) {
                //console.log("Tab", element);
                dataObj['text'] = element;
                dataObj['showAmount'] = false;
                dataObj['indent'] = true;
            }
            else {
                dataObj['text'] = element;
                dataObj['showAmount'] = false;
                dataObj['indent'] = false;
            }

            textRows.push(dataObj);
            textRowsLocal.push({ text: element })
            //console.log("Index: ", index, 'Row: ', element)
        }

        //console.log("Rows: ", textRows)
        setConnRows(textRowsLocal);
        saveConnRows(database, shuntData.NO_ID_FIELD, textRows, SessionParamsEnum.SessionID.getContextValue(sessionParams));
    }

    function renderText(value, index, isBold) {
        // Replace each tab character in the value with 4 spaces
        const tabSize = 9;
        const spaces = '\u00A0'.repeat(tabSize);
        const text = value.replace(/\t/g, spaces);
        return (
            <Typography key={'spec-' + index} style={{ width: '480px', whiteSpace: 'pre-wrap', fontWeight: isBold ? "bold" : "normal" }}>
                {text}
            </Typography>
        )
    }

    return (
        <Fragment>
            {openEdit === true ? (
                <Box style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                    <textarea className="textarea-box" style={{ height: '350px', minHeight: '100px', maxHeight: '630px' }} value={specValue} onChange={handleChangeSpec} onKeyDown={handleKeyDown} placeholder="Skriv specifikationsdata data här..." />
                    {connRows?.length > 0 ? (
                        <>
                            <Typography style={{ width: '480px', whiteSpace: 'pre-wrap', fontWeight: "bold" }}>
                                Connected™ tillbehör{shuntData?.PLData?.prodLine === PRODUCTLINE_GREEN ? " (Ej green):" : ":"}
                            </Typography>
                            <textarea className="textarea-box" style={{ height: '150px', minHeight: '90px', maxHeight: '430px' }} value={connValue} onChange={handleChangeConnSpec} onKeyDown={handleKeyDownSpec} placeholder="Skriv Connected™ tillbehör data här..." />
                        </>
                    ) : ("")}

                </Box>
            ) : (
                <Fragment>
                    <Fragment>
                        {specRows != null ? (
                            <Box style={{ marginTop: '5px', paddingBottom: "20px" }}>
                                {specRows.map((row, index) => {
                                    return renderText(row?.text, index, row.bold === true);
                                })}

                                {connRows?.length > 0 ? (
                                    <>
                                        <Typography style={{ width: '480px', whiteSpace: 'pre-wrap', fontWeight: "bold" }}>
                                            Connected™ tillbehör{shuntData?.PLData?.prodLine === PRODUCTLINE_GREEN ? " (Ej green):" : ":"}
                                        </Typography>

                                        {connRows.map((row, index) => {
                                            return renderText(row?.text, index, row.bold === true);
                                        })}
                                    </>
                                ) : ("")}
                            </Box>
                        ) : (
                            <Stack spacing={0.1} style={{ paddingBottom: "20px" }}>
                                <InspectPageComponentRow component={'blockInfo'} useCurrent={useCurrent} shuntData={shuntData} />
                            </Stack>
                        )}
                    </Fragment>
                </Fragment>
            )}
            {user?.isAnonymous !== true && user?.isInternal === true && SessionParamsEnum.Editable.getContextValue(sessionParams) === true ? (
                <Fragment>
                    {openEdit ? (
                        <Stack direction={'row'} style={{ position: 'relative' }}>
                            <Tooltip title="Spara ändringar">
                                <IconButton size="small" onClick={saveAllData}>
                                    <SaveIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Stäng utan att spara">
                                <IconButton size="small" onClick={() => { setOpenEdit(!openEdit) }}>
                                    <CloseIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    ) : (
                        <Stack direction={'row'} style={{ position: 'relative' }}>
                            <Tooltip title="Redigera specifikation">
                                <IconButton size="small" onClick={() => { setOpenEdit(!openEdit) }}>
                                    <EditIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Återställ fördefinierad specifikation.">
                                <IconButton size="small" onClick={() => { setReset(true) }}>
                                    <SettingsBackupRestoreIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    )}
                </Fragment>
            ) : ("")}
        </Fragment>

    )
}