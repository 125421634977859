import { ref, set, update } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum";
import { AdjustmentsEnum } from "../../enums/adjustmentsEnum";

const saveAdjustData = function saveAdjustData(database, sessionParams, adjustParams, data = null) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    //console.log("ShuntID:", shuntID);
    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/AdjustData");
    let snapData = {};

    if (data != null) {
        snapData = data;
    }
    else {
        snapData = {
            connectionDesign: AdjustmentsEnum.ConnectionDesign.getContextValue(adjustParams) || null,
            connectionValue: AdjustmentsEnum.ConnectionValue.getContextValue(adjustParams) || null,
            material: AdjustmentsEnum.MaterialValue.getContextValue(adjustParams) || null,
            thermo: AdjustmentsEnum.ThermoValue.getContextValue(adjustParams) || null,
            isolation: AdjustmentsEnum.IsolationValue.getContextValue(adjustParams) || null,
            cutOff: AdjustmentsEnum.CutOffValue.getContextValue(adjustParams) || null,
            manometer: AdjustmentsEnum.ManometerValue.getContextValue(adjustParams) || null,
            stand: AdjustmentsEnum.StandValue.getContextValue(adjustParams) || null,
            cabinet: AdjustmentsEnum.CabinetValue.getContextValue(adjustParams) || null,
            actuators: AdjustmentsEnum.ActuatorsValue.getContextValue(adjustParams) || null,
            dripPlate: AdjustmentsEnum.DripPlateValue.getContextValue(adjustParams) || null,
            adjustValue: AdjustmentsEnum.AdjustValue.getContextValue(adjustParams) || null,
            mixingVessels: AdjustmentsEnum.MixingVessels.getContextValue(adjustParams) || null,
            connTemp: AdjustmentsEnum.ConnectedTemp.getContextValue(adjustParams) || null,
            connDiff: AdjustmentsEnum.ConnectedDiff.getContextValue(adjustParams) || null
        }
    }

    const promises = [];

    if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
        promises.push(update(driftParamsRef, snapData));
    }
    
    return Promise.all(promises);
}

const clearAdjustData = function clearAdjustData(database, sessionParams) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);

    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/AdjustData");

    const promises = [];

    if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
        promises.push(set(driftParamsRef, null));
    }

    return Promise.all(promises);
}

export { saveAdjustData, clearAdjustData } 