import { useState, useEffect } from 'react';
import { Stack, Typography, DialogContent, DialogActions, Box, Button, Step, StepButton, StepLabel, Stepper, Fade, Menu, MenuItem, Tooltip, ClickAwayListener } from "@mui/material"
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDatabase } from 'reactfire';
import { ref, serverTimestamp, set } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { useAdjustParams, useCalcParams, useDevbarParams, useDriftParams, useErrorContext, useNoticeContext, useProductLineParams, usePumpParams, useSessionParams, useShuntParams, useWizardParams } from '../../../contexts';
import { SessionParamsEnum, ErrorEnum, ProductLineEnum, ShuntParamsEnum, CalcParamsEnum, AdjustmentsEnum, DriftParamEnum, NoticeEnum, WizardParamEnum } from "../../../enums";
import { BootstrapDialog, BootstrapDialogTitle } from '../../utils/bootstrapDialog'
import { ClearContext } from "../../utils/clearContext";
import { GreenLvlModal } from "../driftParam/components/greenLvlModal";
import { ConfirmChangedPrinciple } from "../principlePicker/components/confirmChangedPrinciple";
import { Step2, Step3, Step4, Step1 } from '../../steps';
import { StepRes } from '../../steps/stepRes';
import { getCurrentHighPosition } from "../../../firebase/functions/getCurrentHighPosition";
import { useGetShuntListName } from "../../../firebase/functions/getShuntList";
import { deleteShunt } from "../../../firebase/functions/deleteShunt";
import { saveProductLineDataInputs } from "../../../firebase/functions/saveProductLineData";
import { useLastConnected } from "../../../hooks/useLastConnected";
import { resetMaxStepFunc } from "../../../functions/HelperFunctions/resetMaxStep";
import { EditedShuntModal } from "../editedShuntsModal/editedShuntsModal";
import { useContextAuth } from "../../../authContext/AuthHook";

import { SESSION_PATH } from "../../../constants/firebasePaths";
import { NO_MIXINGVESSEL } from "../../../constants/adjustmentsOptions";
import { COMPLETED_SHUNT_API } from "../../../constants/generalText/sessionAPITexts";
import { PRODUCTLINE_GREEN, PRODUCTPRINCIP_VA1 } from "../../../constants/productlineConstants";
import { CV_SCHNEIDER, CV_SIEMENS } from "../../../constants/shuntOptions";

import CheckIcon from '@mui/icons-material/Check';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AddMixingVesselModal } from './components/addMixingVesselModal';
import { clearShuntData } from '../../../firebase/functions/saveShuntData';
import { clearCalcData } from '../../../firebase/functions';
import { clearAdjustData } from '../../../firebase/functions/saveAdjustData';

const steps = ['Kopplingsprincip', 'Parametrar', 'Utförande', 'Pumpval', 'Granska'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#555555',
            borderTopWidth: '1px',
        },

    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#555555',
            borderTopWidth: '1px',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {

        borderTopWidth: 1,
        borderRadius: 1,
    },
}));


// @ts-ignore
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#06559e',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#e12427ad',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <RemoveCircleOutlineRoundedIcon style={{ height: '18px' }} />,
        2: <RemoveCircleOutlineRoundedIcon style={{ height: '18px' }} />,
        3: <RemoveCircleOutlineRoundedIcon style={{ height: '18px' }} />,
        4: <RemoveCircleOutlineRoundedIcon style={{ height: '18px' }} />,
        5: <CheckIcon style={{ height: '18px' }} />,
    };

    return (
        /*@ts-ignore*/
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export default function HorizontalLinearStepper() {
    const sessionParams = useSessionParams();
    const { user } = useContextAuth();
    useLastConnected();

    const getStep = () => {
        let hash = window.location.hash;

        switch (hash) {
            case "#step1":
                return 0;
            case "#step2":
                return 1;
            case "#step3":
                return 2;
            case "#step4":
                return 3;
            case "#step5":
                return 4;
            default:
                //console.log("Current Step: ", SessionParamsEnum.CurrentStep.getContextValue(sessionParams))
                if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) != null) {
                    return Number(SessionParamsEnum.CurrentStep.getContextValue(sessionParams));
                }
                // else if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) != undefined) {
                //     return Number(SessionParamsEnum.MaxStep.getContextValue(sessionParams));
                // }
                else {
                    return 0;
                }
        }
    }

    const [activeStep, setActiveStep] = useState(getStep());
    const [completed, setCompleted] = useState({});

    const navigate = useNavigate();

    const database = useDatabase();
    const productLine = useProductLineParams();

    const adjustParams = useAdjustParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const shuntParams = useShuntParams();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const pumpParams = usePumpParams();
    const wizardParams = useWizardParams();

    const Errorlist = new ErrorEnum();
    const Noticelist = new NoticeEnum();
    const devParams = useDevbarParams();

    const { shuntList, loading } = useGetShuntListName();
    const [openModalLeaveSession, setOpenModalLeaveSession] = useState(false);

    const [openEdited, setOpenEdited] = useState(false);
    const [editedHasErrors, setEditedHasErrors] = useState(false);
    const handleOpenEdited = () => setOpenEdited(true);
    const handleCloseEdited = () => {
        if (editedHasErrors === true) {
            SessionParamsEnum.OpeningSessionModifiedError.setContextValue(sessionParams, false);
            SessionParamsEnum.OpenOverview.setContextValue(sessionParams, true);
        }
        else {
            SessionParamsEnum.OpeningSessionModified.setContextValue(sessionParams, false);
        }
        setOpenEdited(false);
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleModalApprove = () => {
        setOpenModalLeaveSession(false);

        const sessionRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + "/lastClosed")
        set(sessionRef, serverTimestamp());

        <ClearContext />
        navigate("/home", { replace: true });
        // var url_ob = new URL(window.location.href);
        // var new_url = url_ob.pathname + '#step2';
        // document.location.href = new_url;
        // console.log("Here: ", new_url);
        // navigate(new_url);
    }

    const handleModalDecline = () => {
        setOpenModalLeaveSession(false);
    }

    const handleClickOpen = () => {
        if (SessionParamsEnum.ApiMethod.getContextValue(sessionParams) !== 'update' && SessionParamsEnum.ApiMethod.getContextValue(sessionParams) !== 'UPD') {
            setOpenModalLeaveSession(true);
        }
    };

    const [openTooltip, setOpenTooltip] = useState(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(!openTooltip);
    };

    const [green2ModalOpen, setGreen2ModalOpen] = useState(false)

    const handleGreen2ModalOpen = () => {
        setGreen2ModalOpen(true);
    }
    const handleGreen2ModalClose = () => {
        setGreen2ModalOpen(false);
    }

    const [openMixingVesselModal, setOpenMixingVesselModal] = useState(false);

    const handleOpenMixingVesselModal = () => setOpenMixingVesselModal(true);
    const handleCloseMixingVesselModal = () => {
        continueStep();
        setOpenMixingVesselModal(false);
    }

    const [openOverView, setOpenOverView] = useState(false);
    const handleOpenOverView = () => {
        SessionParamsEnum.OpenOverview.setContextValue(sessionParams, true);
    }
    const handleCloseOverView = () => setOpenOverView(false);

    const style = {
        position: 'fixed',
        top: '490px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        height: '600px',
        maxHeight: 'auto',
        bgcolor: 'rgba(255,255,255,0.85)',
        border: '2px solid #E1E2E4',
        borderRadius: 2,
        boxShadow: 24,
        padding: "20px",
        overflow: 'hidden',
    };

    /*
    useEffect(() => {
        if (SessionParamsEnum.SessionID.getContextValue(sessionParams) == undefined || SessionParamsEnum.SessionID.getContextValue(sessionParams) == ""){
            SessionParamsEnum.createSessionID(sessionParams);
        }
    }, [SessionParamsEnum.SessionID.getContextValue(sessionParams)])*/
    //const { pathname } = useLocation();



    // useEffect(() => {
    //     console.log('pathname: ' + pathname)

    //     window.addEventListener('beforeunload', function (e) {
    //         console.log('before unload: ' + pathname)
    //         if (pathname === "/stepper") {

    //             e.preventDefault();
    //             e.returnValue = '';
    //         }
    //     });

    // }, [pathname]);

    useEffect(() => {
        if (user?.isAnonymous !== true && user?.isInternal === true) {
            if (SessionParamsEnum.OpeningSessionModified.getContextValue(sessionParams) === true) {
                setEditedHasErrors(false);
                handleOpenEdited();
            }
        }
    }, [SessionParamsEnum.OpeningSessionModified.getContextValue(sessionParams)])

    useEffect(() => {
        if (user?.isAnonymous !== true && user?.isInternal === true) {
            if (SessionParamsEnum.OpeningSessionModifiedError.getContextValue(sessionParams) === true) {
                setEditedHasErrors(true);
                handleOpenEdited();
            }
        }
    }, [SessionParamsEnum.OpeningSessionModifiedError.getContextValue(sessionParams)])


    const setCurrentStep = (step) => {
        setActiveStep(step);
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false) {
            SessionParamsEnum.CurrentStep.setContextValue(sessionParams, step)
        }
        else {
            SessionParamsEnum.CurrentStep.setContextValue(sessionParams, step, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams))
        }

    };

    const getStepAfterInit = () => {
        let hash = window.location.hash;
        switch (hash) {
            case "#step1":
                if (activeStep !== 0) {
                    setActiveStep(0)
                }
                break;
            case "#step2":
                if (activeStep !== 1) {
                    setActiveStep(1)
                }
                break;
            case "#step3":
                if (activeStep !== 2) {
                    setActiveStep(2)
                }
                break;
            case "#step4":
                if (activeStep !== 3) {
                    setActiveStep(3)
                }
                break;
            case "#step5":
                if (activeStep !== 4) {
                    setActiveStep(4)
                }
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getStepAfterInit();
    }, [window.location.hash])

    useEffect(() => {
        //console.log("Current: ", SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        if (SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) != null && SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) !== "" && SessionParamsEnum.Editable.getContextValue(sessionParams) === true) {
            shuntList.forEach((shunt) => {
                //console.log("Shunt: ", shunt)
                if (shunt.isStarted === false && shunt.isFound === true && shunt.isAddon !== true && shunt.id !== SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) {
                    console.log("💥 DELETE - ", shunt?.id)
                    deleteShunt(database, shunt.id, SessionParamsEnum.SessionID.getContextValue(sessionParams), sessionParams).then(() => {
                        //console.log("Removed: ", shunt.id);
                    }).catch((error) => {
                        console.error('Could not delete shunt: ', error);
                    })
                }
            })
        }

    }, [SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)])


    const isDisabled = () => {
        //console.log("Active Step:", activeStep)
        if (activeStep === 0) {
            if (productLine.state.productLine != null) {
                if (ProductLineEnum.SelectedProductLine.getContextValue(productLine) != null && ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) != null &&
                    ProductLineEnum.SelectedProductLine.getContextValue(productLine) !== "" && ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) !== "") {
                    return false;
                }
                else {
                    return (ProductLineEnum.ProductLine.getContextValue(productLine) == null || ProductLineEnum.ProductPrincip.getContextValue(productLine) == null)
                }

            }
            else {
                return true;
            }
        }
        else if (activeStep === 1 && (devParams.state.val.devMode === false || devParams.state.val.devMode == null)) {
            //console.log("Is Undef:", Object.keys(calcParams.state.val).length)
            if (calcParams.state.val != null && Object.keys(calcParams.state.val).length !== 0 && ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) != null && ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) !== "") {
                //console.log("Errors:", Errorlist.hasErrors(errorContext))
                return Errorlist.hasErrors(errorContext);
            }
            else {

                return true;
            }
        }
        else if (activeStep === steps.length - 1) {
            return true;
        }
        else {

            return false;

        }
    }

    const isDisabled2 = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false) {
            return true;
        }
        else {
            return false;
        }
    }

    const continueStep = () => {
        setCurrentStep(activeStep + 1);
        setHashValue(activeStep + 1);
    }

    const [openConfirmChange, setOpenConfirmChange] = useState(false);


    const handleOpenConfirmChangeModal = () => { setOpenConfirmChange(true) }
    const handleCloseConfirmChangeModal = () => {
        setOpenConfirmChange(false);
    }

    const handleConfirmChange = async () => {
        let oldProdPrinciple = ProductLineEnum.ProductPrincip.getContextValue(productLine);

        ProductLineEnum.ProductLine.setContextValue(productLine, ProductLineEnum.SelectedProductLine.getContextValue(productLine));
        ProductLineEnum.ProductPrincip.setContextValue(productLine, ProductLineEnum.SelectedProductPrincip.getContextValue(productLine));

        resetMaxStepFunc(sessionParams, database);
        //SessionParamsEnum.MaxStep.setContextValue(sessionParams, 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));

        if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) == null || ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) === -1) {
            let pos = await getCurrentHighPosition(database, sessionParams);
            ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, pos + 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
        }
        ShuntParamsEnum.clearData(shuntParams);
        CalcParamsEnum.clearData(calcParams);
        WizardParamEnum.clearData(wizardParams);
        AdjustmentsEnum.clearData(adjustParams);

        clearShuntData(database, sessionParams);
        clearCalcData(database, sessionParams);
        clearAdjustData(database, sessionParams);

        Errorlist.clearAllErrors(errorContext);
        Noticelist.clearAllNotices(noticeContext);

        CalcParamsEnum.clearDataFromFB(calcParams, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        DriftParamEnum.clearDataForNewShunt(driftParams, database, ProductLineEnum.SelectedProductPrincip.getContextValue(productLine), oldProdPrinciple, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), wizardParams);

        saveProductLineDataInputs(database, sessionParams, ProductLineEnum.SelectedProductLine.getContextValue(productLine), ProductLineEnum.SelectedProductPrincip.getContextValue(productLine));

        setCurrentStep(activeStep + 1);
        setHashValue(activeStep + 1);
        handleCloseConfirmChangeModal();
    }

    const handleNext = async () => {
        if (activeStep === 0) {
            //console.log("NEXT!", activeStep)
            if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) >= 1) {
                if ((ProductLineEnum.SelectedProductLine.getContextValue(productLine) !== ProductLineEnum.ProductLine.getContextValue(productLine) || ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) !== ProductLineEnum.ProductPrincip.getContextValue(productLine)) && ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) != null && ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) !== "") {
                    handleOpenConfirmChangeModal();
                }
                else {
                    setCurrentStep(activeStep + 1);
                    setHashValue(activeStep + 1);
                }
            }
            else {
                ProductLineEnum.ProductLine.setContextValue(productLine, ProductLineEnum.SelectedProductLine.getContextValue(productLine))
                ProductLineEnum.ProductPrincip.setContextValue(productLine, ProductLineEnum.SelectedProductPrincip.getContextValue(productLine));

                if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) == null || ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) === -1) {
                    let pos = await getCurrentHighPosition(database, sessionParams);
                    ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, pos + 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                }
                //WizardParamEnum.clearData(wizardParams);
                saveProductLineDataInputs(database, sessionParams, ProductLineEnum.SelectedProductLine.getContextValue(productLine), ProductLineEnum.SelectedProductPrincip.getContextValue(productLine));

                setCurrentStep(activeStep + 1);
                setHashValue(activeStep + 1);
            }
        }
        else {
            if (activeStep === 2) {
                if (SessionParamsEnum.Editable.getContextValue(sessionParams) === true && ProductLineEnum.ProductPrincip.getContextValue(productLine) === PRODUCTPRINCIP_VA1 && (AdjustmentsEnum.MixingVessels.getContextValue(adjustParams) == null || AdjustmentsEnum.MixingVessels.getContextValue(adjustParams) === "")) {
                    if (user?.isInternal !== true && user?.isDev !== true){
                        handleOpenMixingVesselModal();
                    }
                    else{
                        AdjustmentsEnum.MixingVessels.setContextValue(adjustParams, NO_MIXINGVESSEL);
                        setCurrentStep(activeStep + 1);
                        setHashValue(activeStep + 1);
                    }
                    
                }
                else {
                    handleAddOrRemoveMixingVessel();
                    setCurrentStep(activeStep + 1);
                    setHashValue(activeStep + 1);
                }
            }
            else if (activeStep === 1 && ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2 && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== CV_SCHNEIDER) {
                handleGreen2ModalOpen();
            }
            else {
                setCurrentStep(activeStep + 1);
                setHashValue(activeStep + 1);
            }
        }

        //console.log("Step: ", activeStep)
    };

    const handleAddOrRemoveMixingVessel = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === true) {
            if (AdjustmentsEnum.MixingVessels.getContextValue(adjustParams) !== NO_MIXINGVESSEL && AdjustmentsEnum.MixingVessels.getContextValue(adjustParams) != null) {
                if (ShuntParamsEnum.Addons.getContextValue(shuntParams) == null || ShuntParamsEnum.Addons.getContextValue(shuntParams)['mixingVessel'] == null) {
                    let addonData = {
                        size: AdjustmentsEnum.MixingVessels.getContextValue(adjustParams),
                    }

                    SessionParamsEnum.createShuntAddon(sessionParams, database, 'mixingVessel', shuntParams, ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams), ProductLineEnum.ProductLine.getContextValue(productLine), shuntList, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), addonData);
                }
                else {
                    console.log("Update pos")
                    SessionParamsEnum.updateShuntAddonPosition(sessionParams, database, ShuntParamsEnum.Addons.getContextValue(shuntParams)['mixingVessel'], ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams), shuntList)
                }
            }
            else if (ShuntParamsEnum.Addons.getContextValue(shuntParams) != null && ShuntParamsEnum.Addons.getContextValue(shuntParams)['mixingVessel'] != null && ProductLineEnum.ProductPrincip.getContextValue(productLine) === PRODUCTPRINCIP_VA1) {
                console.log("Delete Addon!")
                SessionParamsEnum.deleteShuntAddon(sessionParams, database, shuntParams, ShuntParamsEnum.Addons.getContextValue(shuntParams)['mixingVessel'], 'mixingVessel', SessionParamsEnum.CurrentShunt.getContextValue(sessionParams))
            }
        }

    }

    const handleBack = () => {
        setCurrentStep(activeStep - 1);
        setHashValue(activeStep - 1);
    };

    const handleReset = () => {
        //dispatch({ type: 'clear' });
        setCurrentStep(0);
        setCompleted({});
    };


    const handleStep = (index) => async () => {
        if (activeStep === 0) {
            if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) >= 1) {
                if ((ProductLineEnum.SelectedProductLine.getContextValue(productLine) !== ProductLineEnum.ProductLine.getContextValue(productLine) || ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) !== ProductLineEnum.ProductPrincip.getContextValue(productLine)) && ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) != null && ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) !== "") {
                    handleOpenConfirmChangeModal();
                }
                else {
                    setCurrentStep(index);
                    setHashValue(index);
                }
            }
            else {
                if (ProductLineEnum.SelectedProductLine.getContextValue(productLine) != null && ProductLineEnum.SelectedProductLine.getContextValue(productLine) !== "" &&
                    ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) != null && ProductLineEnum.SelectedProductPrincip.getContextValue(productLine) !== "") {

                    ProductLineEnum.ProductLine.setContextValue(productLine, ProductLineEnum.SelectedProductLine.getContextValue(productLine))
                    ProductLineEnum.ProductPrincip.setContextValue(productLine, ProductLineEnum.SelectedProductPrincip.getContextValue(productLine));

                    if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) == null || ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) === -1) {
                        let pos = await getCurrentHighPosition(database, sessionParams);
                        ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, pos + 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                    }

                    saveProductLineDataInputs(database, sessionParams, ProductLineEnum.SelectedProductLine.getContextValue(productLine), ProductLineEnum.SelectedProductPrincip.getContextValue(productLine));

                    setCurrentStep(index);
                    setHashValue(index);
                }

            }
        }
        else {
            //console.log("STEP: ", SessionParamsEnum.CurrentStep.getContextValue(sessionParams), '=>', index);
            if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) === 2 && index > 2) {
                handleAddOrRemoveMixingVessel();
            }

            if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) === 2 && index > 2 && ProductLineEnum.ProductPrincip.getContextValue(productLine) === PRODUCTPRINCIP_VA1 && (AdjustmentsEnum.MixingVessels.getContextValue(adjustParams) == null || AdjustmentsEnum.MixingVessels.getContextValue(adjustParams) === "")) {
                if (SessionParamsEnum.Editable.getContextValue(sessionParams) === true) {
                    if (user?.isInternal !== true && user?.isDev !== true){
                        handleOpenMixingVesselModal();
                    }
                    else{
                        AdjustmentsEnum.MixingVessels.setContextValue(adjustParams, NO_MIXINGVESSEL);
                        setCurrentStep(activeStep + 1);
                        setHashValue(activeStep + 1);
                    }
                    
                }
                else {
                    if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) === 4 && !isDisabled()) {
                        setCurrentStep(index);
                        setHashValue(index);

                    } else if (index === (activeStep + 1) && !isDisabled()) {
                        setCurrentStep(index);
                        setHashValue(index);
                    }

                    if (index <= activeStep) {
                        setCurrentStep(index);
                        setHashValue(index);
                    }
                }

            }
            else if (index === 2 && ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2 && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== CV_SIEMENS) {
                handleGreen2ModalOpen();
            }
            else {
                if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) === 4 && !isDisabled()) {
                    setCurrentStep(index);
                    setHashValue(index);

                } else if (index === (activeStep + 1) && !isDisabled()) {
                    setCurrentStep(index);
                    setHashValue(index);
                }

                if (index <= activeStep) {
                    setCurrentStep(index);
                    setHashValue(index);
                }
            }
        }


    }

    useEffect(() => {
        if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) != null) {
            setHashValue(SessionParamsEnum.CurrentStep.getContextValue(sessionParams))
        }
    }, [SessionParamsEnum.CurrentStep.getContextValue(sessionParams)])

    const setHashValue = (step) => {
        let url_ob = new URL(window.location.href);
        let new_url;
        switch (step) {
            case 0:
                new_url = url_ob.pathname + '#step1';
                navigate(new_url);
                break;
            case 1:
                new_url = url_ob.pathname + '#step2';
                document.location.href = new_url;
                navigate(new_url);
                break;
            case 2:
                new_url = url_ob.pathname + '#step3';
                document.location.href = new_url;
                navigate(new_url);
                break;
            case 3:
                new_url = url_ob.pathname + '#step4';
                document.location.href = new_url;
                navigate(new_url);
                break;
            case 4:
                new_url = url_ob.pathname + '#step5';
                document.location.href = new_url;
                navigate(new_url);
                break;

            default:
                return ('No Data')
        }
    }

    const showStep = (step) => {

        switch (step) {
            case 0:
                return (
                    <Step1 />
                )
            case 1:
                return (
                    <Step2 />
                )
            case 2:
                return (
                    <Step3 />
                )
            case 3:
                return (
                    <Step4 />
                )

            case 4:
                return (
                    <StepRes />
                )
            default:
                return ('No Data')
        }
    }

    const createNew = async () => {
        clearContexts();
        const shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
        //console.log("Shunt: ", shuntID);
        handleClose();
        SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 0, database, shuntID);
        navigate("/stepper");
    }


    const clearContexts = () => {
        //console.log("Clear! - Stepper");
        sessionParams.dispatch({ type: 'clearStepData' })
        adjustParams.dispatch({ type: 'clear' })
        calcParams.dispatch({ type: 'clear' })
        driftParams.dispatch({ type: 'clear' })
        errorContext.dispatch({ type: 'clearErrorList' })
        noticeContext.dispatch({ type: 'clearNoticeList' })
        productLine.dispatch({ type: 'clear' })
        pumpParams.dispatch({ type: 'clear' })
        shuntParams.dispatch({ type: 'fullClear' })
    }
    //console.log('disabled: ', isDisabled())

    useEffect(() => {
        if ((SessionParamsEnum.CurrentStep.getContextValue(sessionParams) == null) && (SessionParamsEnum.MaxStep.getContextValue(sessionParams) == null)) {
            setCompleted({});
        }
        else if ((SessionParamsEnum.CurrentStep.getContextValue(sessionParams) != null)) {
            if ((SessionParamsEnum.MaxStep.getContextValue(sessionParams) === 0)) {
                setCompleted([]);
            } else if ((SessionParamsEnum.MaxStep.getContextValue(sessionParams) === 1)) {
                setCompleted([1,]);
            } else if ((SessionParamsEnum.MaxStep.getContextValue(sessionParams) === 2)) {
                setCompleted([1, 1,]);
            } else if ((SessionParamsEnum.MaxStep.getContextValue(sessionParams) === 3)) {
                setCompleted([1, 1, 1,]);
            } else if ((SessionParamsEnum.MaxStep.getContextValue(sessionParams) === 4)) {
                setCompleted([1, 1, 1, 1, 1,]);
            }
        }
    }, [SessionParamsEnum.MaxStep.getContextValue(sessionParams), SessionParamsEnum.CurrentStep.getContextValue(sessionParams)])

    return (
        <>
            <div style={{
                margin: "15px 0px 0px 0px",
                width: "1059px",
                borderRadius: "0px",
                boxShadow: "inset 1px 1px 10px #91919140 ",
                padding: "20px",
                justifyContent: 'center',
                backgroundColor: '#E1E2E4',
            }}>
                <Box sx={{ float: 'right', position: 'absolute', marginLeft: '975px', marginTop: '-76px', pb: 2, }}>
                    <Stack direction={"row"}>
                        <IconButton
                            disabled={activeStep === 0 || ShuntParamsEnum.IsCustomShunt.getContextValue(shuntParams) || (activeStep === 1 && (SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'update' || SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'UPD'))}
                            onClick={handleBack}
                            sx={{ mr: 1, fontSize: '25px', color: '#0055a0' }}
                        >
                            <ArrowCircleLeftIcon />
                        </IconButton>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep === steps.length - 1 ? (
                            <>
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) === true && (SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'create' || SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'INS') ? (
                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                        <div>
                                            <Tooltip
                                                arrow
                                                title={COMPLETED_SHUNT_API}
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                placement={'bottom-end'}
                                                onClose={handleTooltipClose}
                                                open={openTooltip}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener>
                                                <IconButton id="fade-button"
                                                    aria-controls={open ? 'fade-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleTooltipOpen} sx={{ fontSize: '25px', color: '#0055a0' }}>
                                                    <CheckCircleIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </ClickAwayListener>

                                ) : (
                                    <IconButton id="fade-button"
                                        aria-controls={open ? 'fade-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick} sx={{ fontSize: '25px', color: '#0055a0' }}>
                                        <CheckCircleIcon />
                                    </IconButton>
                                )}
                            </>

                        ) : (
                            <IconButton onClick={handleNext} disabled={isDisabled()} sx={{ fontSize: '25px', color: '#0053A1' }}>
                                <ArrowCircleRightIcon />
                            </IconButton>
                        )}
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={(e) => { handleOpenOverView(); handleClose(); }}>Gå till översikt</MenuItem>
                            {(isDisabled2() !== true && SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) && <MenuItem onClick={(e) => { createNew(); handleClose(); }}>Lägg till shunt</MenuItem>}
                        </Menu>
                    </Stack>

                </Box>
                <Box>
                    <Stack direction={"row"}>
                        <div onClick={handleClickOpen} style={{ cursor: "pointer", marginRight: '20px', paddingLeft: '8px', paddingRight: '8px' }}>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ paddingRight: '8px', paddingTop: '3px' }}>
                                    <svg style={{ color: 'rgba(0, 0, 0, 0.38)', height: '1.5em', width: '1.5em' }} focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="12" style={{ fill: 'rgba(0, 0, 0, 0.38)' }}></circle>
                                        <text style={{ fill: '#fff', fontFamily: 'roboto', color: 'rgba(0, 0, 0, 0.38)' }} x="12" y="16" textAnchor="middle">0</text>
                                    </svg>
                                </span>
                                <span className="MuiStepLabel-labelContainer">
                                    <span className="MuiStepLabel-label css-18k7o0v-MuiStepLabel-label">Start</span>
                                </span>
                            </span>
                        </div>

                        <Stepper nonLinear activeStep={activeStep} style={{ width: '95%' }} connector={<QontoConnector />}>

                            {steps.map((label, index) => (
                                <Step key={label} active={index === activeStep} completed={completed[index] && index !== activeStep} >
                                    <StepButton color="inherit" onClick={handleStep(index)} disabled={ShuntParamsEnum.IsCustomShunt.getContextValue(shuntParams) || ShuntParamsEnum.IsAddon.getContextValue(shuntParams) === true || (index === 0 && (SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'update' || SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'UPD'))}>
                                        {ShuntParamsEnum.IsCustomShunt.getContextValue(shuntParams) === true || ShuntParamsEnum.IsAddon.getContextValue(shuntParams) === true || (index === 0 && (SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'update' || SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'UPD')) ? (
                                            <StepLabel
                                                StepIconComponent={ColorlibStepIcon}
                                            >
                                                {label}
                                            </StepLabel>
                                        ) : (
                                            <StepLabel>
                                                {label}
                                            </StepLabel>
                                        )}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                </Box>
                <Box sx={{ width: '100%' }}>
                    {activeStep === steps.length ? (
                        <>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </>
                    ) : (
                        <>

                            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep}</Typography> */}
                            {showStep(activeStep)}

                        </>
                    )}
                </Box>
            </div>

            <BootstrapDialog
                onClose={handleModalDecline}
                aria-labelledby="customized-dialog-title"
                open={openModalLeaveSession}
            >
                <BootstrapDialogTitle onClose={handleModalDecline}>
                    Lämna session?
                </BootstrapDialogTitle>
                <DialogContent>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                        Är du säker på att du vill lämna nuvarande session?
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalApprove}>
                        Ja
                    </Button>
                    <Button onClick={handleModalDecline}>
                        Nej
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <GreenLvlModal openModal={green2ModalOpen} handleClose={handleGreen2ModalClose} continueStep={continueStep} />
            {/* 
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openOverView}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <ShuntOverview style={style} open={openOverView} handleClose={handleCloseOverView} />
            </Modal>
            */}

            <ConfirmChangedPrinciple openModal={openConfirmChange} handleModalClose={handleCloseConfirmChangeModal} handleContinue={handleConfirmChange} />

            <EditedShuntModal open={openEdited} handleClose={handleCloseEdited} hasErrors={editedHasErrors} />

            <AddMixingVesselModal open={openMixingVesselModal} handleClose={handleCloseMixingVesselModal} shuntList={shuntList} />
        </>
    );
}
